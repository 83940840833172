<template>
    <div class="row">
        <div class="col">
            <h5 class="h6 my-3">{{$t('motor.servisni-kniha')}}:</h5>
            <div class="list w-100 pb-5">


                <div class="d-inline-block activity m-1" v-for="a in aktivity" :key="a.id">
                    <a href="#" data-toggle="modal"  data-target="#activityModal" @click.prevent="showActivity(a)">
                        <div class="media p-1">
                            <div class="icon flex-fill align-self-center text-success">
                                <i class="fas fa-check state"></i>
                                <img class="mr-3 ico-aktivity" :src="ikonaAktivity(a)" alt />
                            </div>
                            <div class="info media-body align-self-center">
                                <p class="text-truncate mb-0">{{$t('motor.aktivita')}}: {{ activityTypeName(a) }}</p>
                                <p class="text-truncate mb-0">{{$t('motor.datum')}}: {{ df(a.Time,'LLL') }}</p>
                            </div>
                            <div class="goto media-body align-self-center">
                                <i class="fas fa-angle-double-right"></i>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="d-inline-block activity m-1" >
                    <a href="#">
                        <div class="media p-1">
                            <div class="icon flex-fill align-self-center text-success">
                                <i class="fas fa-check state"></i>
                                <img class="mr-3 ico-aktivity"  src="/img/mob/EngineSmall.png" alt />
                            </div>
                            <div class="info media-body align-self-center">
                                <p class="text-truncate mb-0">{{$t('motor.aktivita')}}: {{ $t('m.CVActivity2.ZaloženíKartyMotoru') }}</p>
                                <p class="text-truncate mb-0">{{$t('motor.datum')}}: {{ df(motor.Time,'LL') }}</p>
                            </div>
                            <div class="goto media-body align-self-center">
                                <i class="fas fa-angle-double-right" style="color:transparent"></i>
                            </div>
                        </div>
                    </a>
                </div>

                <!-- pro ukazku

                <div class="d-inline-block activity m-1">
                  <a href="#" data-toggle="modal" data-target="#activityModal">
                    <div class="media p-1">
                      <div class="icon flex-fill align-self-center text-success">
                        <i class="fas fa-check state"></i>
                        <img class="mr-3" src="/img/aktivita-vyroba.png" alt />
                      </div>
                      <div class="info media-body align-self-center">
                        <p class="text-truncate mb-0">{{$t('motor.aktivita')}}: {{$t('motor.aktivita.vyroba')}}</p>
                        <p class="text-truncate mb-0">{{$t('motor.datum')}}: 15.8.2018</p>
                      </div>
                      <div class="goto media-body align-self-center">
                        <i class="fas fa-angle-double-right"></i>
                      </div>
                    </div>
                  </a>
                </div>


                <div class="d-inline-block activity m-1">
                  <a href="#" data-toggle="modal" data-target="#activityModal">
                    <div class="media p-1">
                      <div class="icon flex-fill align-self-center text-warning">
                        <i class="fas fa-check state"></i>
                        <img class="mr-3" src="/img/aktivita-prevent-udrzba.png" alt />
                      </div>
                      <div class="info media-body align-self-center">
                        <p class="text-truncate mb-0">{{$t('motor.aktivita')}}: {{$t('motor.aktivita.prevent-udrzba')}}</p>
                        <p class="text-truncate mb-0">{{$t('motor.datum')}}: 15.8.2018</p>
                      </div>
                      <div class="goto media-body align-self-center">
                        <i class="fas fa-angle-double-right"></i>
                      </div>
                    </div>
                  </a>
                </div>

                <div class="d-inline-block activity m-1">
                  <a href="#" data-toggle="modal" data-target="#activityModal">
                    <div class="media p-1">
                      <div class="icon flex-fill align-self-center text-danger">
                        <i class="fas fa-check state"></i>
                        <img class="mr-3" src="/img/aktivita-prevent-mereni.png" alt />
                      </div>
                      <div class="info media-body align-self-center">
                        <p class="text-truncate mb-0">{{$t('motor.aktivita')}}: {{$t('motor.aktivita.prevent-mereni')}}</p>
                        <p class="text-truncate mb-0">{{$t('motor.datum')}}: 15.8.2018</p>
                      </div>
                      <div class="goto media-body align-self-center">
                        <i class="fas fa-angle-double-right"></i>
                      </div>
                    </div>
                  </a>
                </div>

                <div class="d-inline-block activity m-1">
                  <a href="#" data-toggle="modal" data-target="#activityModal">
                    <div class="media p-1">
                      <div class="icon flex-fill align-self-center text-warning">
                        <i class="fas fa-check state"></i>
                        <img class="mr-3" src="/img/aktivita-havarijni-servis.png" alt />
                      </div>
                      <div class="info media-body align-self-center">
                        <p class="text-truncate mb-0">{{$t('motor.aktivita')}}: {{$t('motor.aktivita.havarijni-servis')}}</p>
                        <p class="text-truncate mb-0">{{$t('motor.datum')}}: 15.8.2018</p>
                      </div>
                      <div class="goto media-body align-self-center">
                        <i class="fas fa-angle-double-right"></i>
                      </div>
                    </div>
                  </a>
                </div>

                <!- - /pro ukazku -->

                <AktivityModal :akt="akt" class="modal fade" id="activityModal" tabindex="-1" role="dialog" aria-hidden="true"></AktivityModal>

            </div>
        </div>
    </div>
</template>

<script>
    import AktivityModal from "./AktivityModal";

  //import Notifications from "./Notifications";
  const moment = require('moment');
  require('moment/locale/cs');
  moment.locale('cs');
//const qs = require("qs");
const axios = require("axios");

function sortamotora(a,b){
    if (!a.Time) return 0
    var t1 = moment(a.Time).unix()
    var t2 = moment(b.Time).unix()
    return t1<t2 ? 1 : -1

}


axios.defaults.withCredentials = true;
    export default {
        name: "Kniha",
        components: { AktivityModal},
        data(){
            return {
                motorId: this.$route.params.id ,
                motor:[],
                akt:[]
            }

        },
        computed:{
            aktivity(){
                if (!this.motor.activities) return []
                var akt = this.motor.activities
                return akt.sort(sortamotora)
            }
        },
        mounted() {
            //console.log('mounted kniha')
            this.motor = this.$parent.motor
            this.$parent.$on('motorUpdated', () => {
                this.motor = this.$parent.motor
                //console.log('kokon', data)
            })
        },
        methods:{
            showActivity(activity){
                // axios.get(process.env.VUE_APP_API_ROOT_URL + "/motors/"+this.motorId ).then(result => {
                //   if (result.status == 200) {
                //     this.motor = result.data
                //   }
                // })
                //console.log('AkTIVITA',activity.id, activity.user_id, activity.userinfo)
                this.akt = activity
                if (activity.ActivityType=='6'){
                    this.$nextTick(function(){
                      if (this.$refs.defectbody) this.$refs.defectbody.loadDefects()
                    })

                }
            },
            df: function (time, format) {
              moment.locale(this.$i18n.locale);
                if (!time) return '';
                var a = moment(time);
                return a.format(format ? format : "LLL")
            },
            activityTypeName(a){
                /*  {
                  Expedition,
                  Installation,
                  Commissioning,
                  Maintance,
                  Lubrication,
                  Measurement,
                  Defect
              }*/

                var akt = parseInt(a.ActivityType);

                if (akt==0 )return this.$t('m.PExpedition.Expedice')
                if (akt==1 )return this.$t('m.PViewEngine.Instalace')
                if (akt==2 )return this.$t('m.PCommissioning.UvedeníDoProvozu')
                if (akt==3 )return this.$t('m.PViewEngine.PreventivníÚdržba')
                if (akt==4 )return this.$t('m.PViewEngine.PViewEngine_PreventivníMazání')
                if (akt==5 )return this.$t('m.PViewEngine.PViewEngine_PreventivníMěření')
                if (akt==6 )return this.$t('m.PDefect.Porucha')
                if (akt==7 )return this.$t('m.POther.Ostatní')
                if (akt==8 )return this.$t('m.POther.Ostatní')
            },

            ikonaAktivity(a){
                var akt = parseInt(a.ActivityType);
                if ( akt == 4 ) return "/img/mob/lubricationInterval.png";
                if ( akt == 5 ) return "/img/aktivita-prevent-mereni.png";
                if (akt==6) return "/img/aktivita-havarijni-servis.png";

                if ( akt == 2 ) return "/img/mob/Commissioning.png";

                    if (akt==8) return "/img/others.png";

                if (akt==3) return "/img/serviceInterval-33x36.png"

                if (akt<2)return "/img/aktivita-vyroba.png";

                return "/img/aktivita-prevent-udrzba.png";
            },
        }
    }


    </script>

<style scoped>

</style>
